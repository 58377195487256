import React, { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { SubmitButton } from "../shared";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

const GET_SETTINGS = gql`
  query {
    settings {
      edges {
        node {
          whatsUpMessage
        }
      }
    }
  }
`;

const CREATE_SETTINGS = gql`
  mutation($whatsUpMessage: String!) {
    createSettings(whatsUpMessage: $whatsUpMessage) {
      settings {
        whatsUpMessage
      }
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation($changes: SettingsInput!) {
    updateSettings(changes: $changes) {
      settings {
        whatsUpMessage
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const WhatsUpLabel = styled.label`
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  height: 100px;
  width: calc(100% - 160px);
  margin-left: 10px;
`;

export const Settings = () => {
  const settingsQuery = useQuery(GET_SETTINGS, {
    fetchPolicy: "no-cache"
  });
  const [createSettings] = useMutation(CREATE_SETTINGS);
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const [whatsUpMessage, setWhatsUp] = useState("");
  const [hasSettings, setHasSettings] = useState(false);

  useEffect(() => {
    if (settingsQuery.data && settingsQuery.data.settings.edges.length > 0) {
      setWhatsUp(settingsQuery.data.settings.edges[0].node.whatsUpMessage);
      setHasSettings(true);
    }
  }, [settingsQuery.data]);

  const handleChange = useCallback((e) => setWhatsUp(e.target.value));
  const handleSubmit = useCallback(() => {
    if (hasSettings) {
      updateSettings({
        variables: {
          changes: {
            whatsUpMessage
          }
        }
      }).then(
        () => {
          toast.success("Updated successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        },
        () => {
          toast.error("Something went wrong.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    } else {
      createSettings({
        variables: {
          whatsUpMessage
        }
      }).then(
        () => {
          toast.success("Message set successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          setHasSettings(true);
        },
        () => {
          toast.error("Something went wrong.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    }
  }, [updateSettings, createSettings, hasSettings, whatsUpMessage]);
  return (
    <Wrapper>
      <WhatsUpLabel>
        <span>Whats Up Message</span>
        <TextArea name="whatsUpMessage" value={whatsUpMessage} onChange={handleChange} />
      </WhatsUpLabel>
      <SubmitButton value="Save" onClick={handleSubmit} />
      <ToastContainer />
    </Wrapper>
  );
};
