import React, { useState, useEffect, useCallback } from "react";
import { Route, Switch, useLocation, useRouteMatch, Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { colors } from "../../colors";
import {
  StyledLink,
  ModalWrapper,
  Modal,
  StyledSelect,
  ConfirmText,
  ButtonLinkStyles,
  Button,
  LoginSubmitBtn
} from "../shared";
import { HashLink } from "react-router-hash-link";
import { AlertWidget, MemberHome, MemberProfile, useVenueNavContext, useSearchContext } from "./";
import { MemberVenue } from "./venue";
import { MemberOffer } from "./offer";
import { VenueSearchMenu } from "./";
import bubblesBgPng from "../../assets/bubbles.png";
import { MdHome, MdPerson, MdSearch, MdClose } from "react-icons/md";
import { GiBeerStein } from "react-icons/gi";
import { VscBell } from "react-icons/vsc";
import brewSaverLogo from "../../assets/brewsaver-logo-bubbles.png";
import appleShareIcon from "../../assets/apple-share-icon.png";
import installAndroidImg from "../../assets/install_android.png";
import { getCookie, handleLogout } from "../../utility";
import { Copyright } from "../copyright";

//import bubblesBgWebp from "../../assets/bubbles.webp";

const GET_VENUE_DROPDOWN = gql`
  query {
    allActiveVenues {
      venueId
      name
    }
  }
`;

const GET_SETTINGS = gql`
  query {
    settings {
      edges {
        node {
          whatsUpMessage
        }
      }
    }
  }
`;

const HomeButton = styled(HashLink)`
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${colors.navLinkBg};
  color: ${colors.navLinkFont};
  margin: 5px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.defaultBg};
  background-image: url(${bubblesBgPng});
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: auto;
`;

const boxShadow = "5px 5px 10px rgba(0,0,0, .8)";

const Nav = styled.nav`
  margin: 20px;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: ${colors.navBg};
  box-shadow: ${boxShadow};
  width: 80%;
  display: flex;
  justify-content: center;
  min-height: 40px;
`;

const height = "50px";
const width = "100%";

const HeaderPlaceholder = styled.div`
  padding: 25px 0;
  width: ${width};
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  height: ${height};
  width: ${width};
  z-index: 5;
  background-color: ${colors.secondaryTransparent};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavMenu = styled.menu`
  margin: 0;
  padding: 0;
  width: ${({ width }) => width};
  transition: all 1s;
  height: calc(100vh - ${height});
  background-color: ${colors.secondaryTransparent};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  max-width: 100%;
  top: ${height};
  border-top: ${({ width }) => (width !== "0" ? `1px solid ${colors.secondaryOffset}` : "none")};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const NavMenuVertical = styled.menu`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: ${({ height }) => height};
  width: 100%;
  transition: all 1s;
  background-color: ${colors.secondaryTransparent};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  max-width: 100%;
  top: ${height};
  border-top: ${({ height }) => (height !== "0" ? `1px solid ${colors.secondaryOffset}` : "none")};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Home = styled(MdHome)`
  color: ${colors.defaultBg};
  background-color: ${({ isActive }) => (isActive ? `${colors.secondaryTransparent}` : "unset")};
  padding: 5px 10px;
  max-width: 100%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const VenueIcon = styled(GiBeerStein)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.secondaryTransparent}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const Search = styled(MdSearch)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.secondaryTransparent}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const Alert = styled(VscBell)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.secondaryTransparent}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const Profile = styled(MdPerson)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.secondaryTransparent}` : "unset")};
  max-width: 100%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
`;

const MenuCloser = styled(MdClose)`
  padding: 10px;
  color: ${colors.defaultBg};
  max-width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 10px;
  background-color: ${colors.memberIconContainerBg};
  padding: 3px;
  box-sizing: border-box;
`;

const IconWrap = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: ${({ width }) => (width ? width : "30px")};
  height: ${({ height }) => (height ? height : "30px")};
  opacity: 1;
`;

const PromptHeader = styled.h2`
  margin: 15px 0;
`;

const instructionHeight = "80px";
const PromptText = styled.div`
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: ${instructionHeight};
`;

const PromptTextDanger = styled.span`
  font-weight: bold;
  color: ${colors.danger};
`;

const InstructionWrapper = styled.div`
  height: ${instructionHeight};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius-bottom-left: 10px;
  border-radius-bottom-right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const androidInstructionHeight = "100px";
const AndroidPromptText = styled.div`
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: ${androidInstructionHeight};
`;

const AndroidInstructionWrapper = styled.div`
  height: ${androidInstructionHeight};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius-bottom-left: 10px;
  border-radius-bottom-right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const PromptInstruction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
`;

const PromptInstructionText = styled.span`
  padding: 0 8px;
  color: black;
  padding: 1px 5px;
`;

const MacPromptCloser = styled(MdClose)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const BoldUnderline = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

const AlertHeader = styled.h2`
  color: ${colors.secondaryOffset};
  margin: 0 auto;
  padding: 5px 0;
  border-bottom: 1px solid ${colors.secondaryOffset};
  width: 100%;
  text-align: center;
`;

const ProfileHeader = styled.h2`
  color: ${colors.secondaryOffset};
  margin: 0 auto;
`;

const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
`;

const ProfileDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  position: absolute;
  top: 120%;
  top: calc(100% + 20px);
  right: 5%;
  max-width: 60vw;
  width: 200px;
  height: 150px;
  border-radius: 5px;
  border: 2px solid ${colors.secondary};
  background-color: ${colors.secondary};
  z-index: 11;

  &:after {
    content: "";
    position: absolute;
    top: -20px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid ${colors.secondary};
  }
`;

const ProfileDropdownBtn = styled.button`
  background-color: ${colors.primary};
  color: white;
  height: 35px;
  width: 90%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  margin: 5px auto;
`;

const AlertDropdownWrapper = styled.div`
  margin-top: 47.5px;
  position: absolute;
  width: 100vw;
  left: 0;
`;

const AlertDropdown = styled.div`
  display: block;
  min-height: 30vh;
  position: absolute;
  width: 95vw;
  left: 2.5vw;
  border-radius: 5px;
  border: 2px solid ${colors.secondary};
  background-color: ${colors.secondary};
  box-sizing: border-box;
  z-index: 11;
  max-height: 70vh;
  overflow-y: scroll;
`;

const MenuSelect = styled(StyledSelect)`
  width: 100%;
  margin: 0;
  min-width: unset;
  max-width: unset;
  color: black;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 15px auto;
  color: ${colors.secondaryOffset};
`;

const LabelText = styled.span`
  font-weight: 600;
  font-size: 15px;
`;

const VertBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 10px;
`;

const Go = styled.button`
  ${ButtonLinkStyles}
  width: 90%;
  font-size: 18px;
  margin: 0 auto;
`;

const MenuHeader = styled.h2`
  font-size: 20px;
  text-align: center;
  color: ${colors.secondaryOffset};
  margin: 10px 0 0 0;
`;

const getActiveByPath = () => {
  const { pathname } = window.location;
  if (pathname === "/member/profile") {
    return "profile";
  }
  const splitPath = pathname.trim().split("/");
  if (splitPath.length > 2 && splitPath[1] === "member" && splitPath[2] === "venue") {
    return "venue";
  }
  return "home";
};

const cookieName = "install_prompt";
export const MemberLanding = () => {
  let { pathname } = useLocation();
  let { path } = useRouteMatch();
  const [isMacPromptVisible, setMacPrompt] = useState(false);
  const [navMenuWidth, setNavMenuWidth] = useState("0");
  const [navMenuHeight, setNavMenuHeight] = useState("0");
  const [active, setActive] = useState(getActiveByPath());
  const [isProfileDropdownOpen, setProfileDropdown] = useState(false);
  const [isSearchOpen, setSearch] = useState(false);
  const [isAlertOpen, setAlert] = useState(false);
  const [searchData] = useSearchContext();
  const [venueNavData, venueNavDispatch] = useVenueNavContext();
  const venueDropdown = useQuery(GET_VENUE_DROPDOWN, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });

  const settingsQuery = useQuery(GET_SETTINGS, {
    fetchPolicy: "no-cache"
  });
  const [whatsUpMessage, setWhatsUp] = useState("");

  // const bodyClick = useCallback(() => {
  //   setProfileDropdown(false);
  // }, [setProfileDropdown]);

  // useEffect(() => {
  //   document.body.removeEventListener("click",);
  //   document.body.addEventListener("click", bodyClick);
  // }, [bodyClick]);

  useEffect(() => {
    const splitPath = pathname.trim().split("/");
    if (pathname === "/member/profile") {
      setActive("profile");
    } else if (splitPath.length > 2 && splitPath[1] === "member" && splitPath[2] === "venue") {
      setActive("venue");
    } else {
      setActive("home");
    }
  }, [pathname, setActive]);

  useEffect(() => {
    if (settingsQuery.data && settingsQuery.data.settings.edges.length > 0) {
      setWhatsUp(settingsQuery.data.settings.edges[0].node.whatsUpMessage);
    }
  }, [settingsQuery.data]);

  const toggleMenuOn = useCallback(() => {
    setNavMenuWidth("100%");
  }, [setNavMenuWidth]);

  const toggleMenuOff = useCallback(() => {
    setNavMenuWidth("0");
  }, [setNavMenuWidth]);

  const toggleVertMenuOn = useCallback(() => {
    setNavMenuHeight("295px");
  }, [setNavMenuWidth]);

  const toggleVertMenuOff = useCallback(() => {
    setNavMenuHeight("0");
  }, [setNavMenuWidth]);

  const togglePromptOff = useCallback(() => {
    const d = new Date();
    // 1 wk
    const expires = new Date(d.getTime() + 604800000);
    document.cookie = `${cookieName}=closed;expires=${expires.toUTCString()};path=/`;
    setMacPrompt(false);
  }, [setMacPrompt]);

  useEffect(() => {
    if (getCookie(cookieName) === "closed") {
      setMacPrompt(false);
      return;
    }
    let showPrompt = ["iPhone", "iPad", "iPod"].includes(navigator.platform);
    if (showPrompt) {
      const standalone = window.navigator.standalone;
      if (standalone === true || standalone === undefined) {
        showPrompt = false;
      }
    }
    setMacPrompt(false /*showPrompt*/);
  }, [setMacPrompt]);

  const hideProfileDropdown = useCallback(() => {
    setProfileDropdown(false);
  }, [setProfileDropdown]);

  const handleHome = useCallback(() => {
    setActive("home");
    hideProfileDropdown();
    setSearch(false);
    toggleMenuOff();
    setAlert(false);
    toggleVertMenuOff();
  }, [setActive, hideProfileDropdown, toggleMenuOff, setSearch]);

  // const handleSearch = useCallback(() => {
  //   setActive("search");
  //   hideProfileDropdown();
  //   toggleMenuOn();
  // }, [setActive]);

  const showSearch = useCallback(() => {
    hideProfileDropdown();
    toggleVertMenuOff();
    setSearch(true);
    setAlert(false);
    toggleMenuOn();
  }, [setSearch, hideProfileDropdown, toggleMenuOff, setSearch]);

  const hideSearch = useCallback(() => {
    setSearch(false);
    toggleMenuOff();
  }, [setSearch, toggleMenuOff]);

  const completeSearch = useCallback(() => {
    setSearch(false);
    toggleMenuOff();
    setActive("home");
  }, [toggleMenuOff, setSearch, setActive]);

  const showAlert = useCallback(() => {
    setAlert(true);
    setSearch(false);
    toggleMenuOff();
    toggleVertMenuOff();
    hideProfileDropdown();
  }, [setSearch, hideProfileDropdown, setAlert, toggleMenuOff]);

  const hideAlert = useCallback(() => {
    setAlert(false);
  }, [setSearch]);

  const handleProfile = useCallback(() => {
    setActive("profile");
    setSearch(false);
    toggleMenuOff();
  }, [setActive, setSearch, toggleMenuOff]);

  const showProfileDropdown = useCallback(() => {
    setProfileDropdown(true);
    setSearch(false);
    setAlert(false);
    toggleVertMenuOff();
    toggleMenuOff();
  }, [setActive, setProfileDropdown, toggleMenuOff]);

  const handleVenue = useCallback(() => {
    setProfileDropdown(false);
    setSearch(false);
    toggleMenuOff();
    toggleVertMenuOn();
    setAlert(false);
  }, [setProfileDropdown, setSearch, toggleVertMenuOn]);

  const handleViewVenue = useCallback(() => {
    toggleVertMenuOff();
    setActive("venue");
  }, [setActive, toggleVertMenuOff]);

  const logout = useCallback(() => {
    handleLogout();
  }, []);

  const handleSelect = useCallback((value) => {
    if (value === null) {
      value = [];
    }
    venueNavDispatch({
      type: "SET_VENUE",
      payload: value.value
    });
  });

  return (
    <ContentWrapper>
      <HeaderPlaceholder></HeaderPlaceholder>
      <Header>
        <NavMenu width={navMenuWidth}>
          <MenuHeader>Search Offers</MenuHeader>
          <VenueSearchMenu onSearch={completeSearch} />
        </NavMenu>
        <NavMenuVertical height={navMenuHeight}>
          <MenuHeader>Find Venue</MenuHeader>
          <Label>
            <LabelText>Venue</LabelText>
            <MenuSelect
              name="venueName"
              options={
                venueDropdown.data &&
                venueDropdown.data?.allActiveVenues
                  ?.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    return 0;
                  })
                  .map((venue) => ({
                    value: venue.venueId,
                    label: venue.name
                  }))
              }
              isSearchable
              value={venueNavData.venueName}
              onChange={handleSelect}
              isClearable
              placeholder="<None>"
              maxMenuHeight={160}
            />
          </Label>
          <VertBtnWrapper>
            {/* <Go onClick={toggleVertMenuOff}>Cancel</Go> */}
            <Link
              to={`/member/venue${`/${venueNavData.venue}`}`}
              onClick={handleViewVenue}
              style={{ textDecoration: "none" }}
            >
              <Go>Go</Go>
            </Link>
          </VertBtnWrapper>
        </NavMenuVertical>
        {/* <Logo
          height="40px"
          width="40px"
          style={{ marginLeft: "10px" }}
          src={brewSaverLogo}
          alt="brew saver logo"
        /> */}
        <IconContainer>
          <Link style={{ display: "flex" }} to="/member" onClick={handleHome}>
            <Home isActive={active === "home"} size="25px" />
          </Link>
          <IconWrap>
            <Search
              onClick={isSearchOpen ? hideSearch : showSearch}
              isActive={isSearchOpen || active === "search"}
              size="25px"
            />
          </IconWrap>
          <IconWrap>
            <VenueIcon
              onClick={navMenuHeight === "0" ? handleVenue : toggleVertMenuOff}
              isActive={active === "venue" || navMenuHeight !== "0"}
              size="25px"
            />
          </IconWrap>
          <IconWrap>
            <Alert
              onClick={isAlertOpen ? hideAlert : showAlert}
              isActive={isAlertOpen || active === "alert"}
              size="25px"
            />
            {isAlertOpen && (
              <AlertDropdownWrapper>
                <AlertDropdown>
                  <AlertHeader>Notifications</AlertHeader>{" "}
                  <AlertWidget title="What's Up!" content={whatsUpMessage} isActive={true} />
                  <AlertWidget
                    title="Test It Out"
                    content="We've set up a Test Venue so you can redeem an offer and see how it works. Use one of the search options or scroll down from the home screen to find Test Venue. Click 'Access Offer' then the 'Redeem' button to check off the offer. This is what your server will do before giving you the discount. After an offer is checked off, it cannot be used again until it reset."
                    isActive={false}
                  />
                  <AlertWidget
                    title="Install Brew Saver (iOS)"
                    content={
                      <>
                        <PromptText>
                          To install Brew Saver on an iOS Mobile Device{" "}
                          <BoldUnderline>you must use Safari</BoldUnderline> then follow these instructions...
                        </PromptText>
                        <InstructionWrapper>
                          <PromptInstruction>
                            <PromptInstructionText>Just tap</PromptInstructionText>
                            <Logo
                              src={appleShareIcon}
                              alt="share"
                              height="25px"
                              width="auto"
                              style={{ color: colors.linkColor }}
                            />
                            <PromptInstructionText>below</PromptInstructionText>
                          </PromptInstruction>
                          <PromptInstructionText>then 'Add to Home Screen'</PromptInstructionText>
                          {/* <PromptInstructionText>
                            You might need to <span style={{ color: colors.linkColor }}>Edit Actions...</span>
                          </PromptInstructionText> */}
                        </InstructionWrapper>
                      </>
                    }
                    isActive={false}
                  />
                  <AlertWidget
                    title="Install Brew Saver (Android)"
                    content={
                      <>
                        <AndroidPromptText>
                          Tap the prompt shown below when you are on the login page of Brew Saver. To go back
                          to the login page{" "}
                          <Link style={{ color: colors.linkColor }} to="/" onClick={logout}>
                            you must logout
                          </Link>
                          .
                          <img
                            style={{ height: "auto", width: "100%", marginTop: "15px" }}
                            src={installAndroidImg}
                          />
                        </AndroidPromptText>
                        <AndroidInstructionWrapper>
                          <PromptInstructionText style={{ padding: "10px" }}>
                            If you are <PromptTextDanger>not seeing the prompt</PromptTextDanger> try clearing
                            your browsers history, close the browser tab and revisit Brew Saver.
                          </PromptInstructionText>
                        </AndroidInstructionWrapper>
                      </>
                    }
                    isActive={false}
                  />
                </AlertDropdown>
              </AlertDropdownWrapper>
            )}
          </IconWrap>
          <RelativeWrapper onClick={isProfileDropdownOpen ? hideProfileDropdown : showProfileDropdown}>
            <Profile isActive={isProfileDropdownOpen || active === "profile"} size="25px" />
            {isProfileDropdownOpen && (
              <ProfileDropdown>
                <ProfileHeader>Welcome!</ProfileHeader>
                <BtnContainer>
                  <Link
                    style={{ display: "flex", textDecoration: "none" }}
                    to="/member/profile"
                    onClick={handleProfile}
                  >
                    <ProfileDropdownBtn style={{ margin: "5px auto" }}>Profile</ProfileDropdownBtn>
                  </Link>
                  <Link style={{ display: "flex", textDecoration: "none" }} to="/" onClick={logout}>
                    <ProfileDropdownBtn style={{ margin: "5px auto" }}>Logout</ProfileDropdownBtn>
                  </Link>
                </BtnContainer>
              </ProfileDropdown>
            )}
          </RelativeWrapper>
        </IconContainer>
      </Header>
      {/* <Nav>
        <HomeButton to={`${url}`}>Home</HomeButton>
        <StyledLink to={`${url}/profile`}>Profile</StyledLink>
        <StyledLink onClick={handleLogout} to="/">
          Logout
        </StyledLink>
      </Nav> */}
      <Switch>
        <Route path={`${path}/profile`} component={MemberProfile} />
        <Route path={`${path}/offer`} component={MemberOffer} />
        <Route path={`${path}/venue/:venueId`} component={MemberVenue} />
        <Route default component={MemberHome} />
      </Switch>
      {isMacPromptVisible && (
        <ModalWrapper>
          <Modal style={{ backgroundColor: colors.secondary, color: colors.secondaryOffset }}>
            <MacPromptCloser onClick={togglePromptOff} size={"30px"} />
            <Logo src={brewSaverLogo} alt="brew saver logo" height="75px" width="75px" />
            <PromptHeader>Brew Saver App {window.navigator.standalone}</PromptHeader>
            <PromptText>
              Install this application <BoldUnderline>using Safari</BoldUnderline> for quick and easy access
              from your home screen when you're ready to explore Colorado's best venues!{" "}
              {window.navigator.standalone}
            </PromptText>
            <InstructionWrapper>
              <PromptInstruction>
                <PromptInstructionText>Just tap</PromptInstructionText>
                <Logo
                  src={appleShareIcon}
                  alt="share"
                  height="25px"
                  width="auto"
                  style={{ color: colors.linkColor }}
                />
                <PromptInstructionText>then 'Add to Home Screen'</PromptInstructionText>
              </PromptInstruction>
              <PromptInstructionText>
                You might need to <span style={{ color: colors.linkColor }}>Edit Actions...</span>
              </PromptInstructionText>
            </InstructionWrapper>
            {/* <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Yes, Delete</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper> */}
          </Modal>
        </ModalWrapper>
      )}
      <Copyright color="white" />
    </ContentWrapper>
  );
};
export default MemberLanding;
