import React, { useCallback } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import { useTableViewModelContext, TableCell } from ".";
import { colors } from "../../colors";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CellLink = styled(Link)`
  cursor: pointer;
  z-index: 1;
  position: relative;
  align-self: center;
  text-decoration: none;
  color: ${colors.tableLink};
  text-shadow: 0px 0px 0.65px ${colors.tableLink}, 0px 0px 0.65px ${colors.tableLink},
    0px 0px 0.65px ${colors.tableLink};

  &:hover {
    text-shadow: none;
    text-decoration: underline;
  }
`;

// { header: "Venue", accessor: "venue" },
//     {
//       header: "Offer Type",
//       accessor: (row) => `${JSON.parse(row.offerType).label}`,
//       link: (row) => `/admin/manage/offer/${row.offerId}`
//     },
//     { header: "Status", accessor: "offerStatus" },
//     { header: "Redemptions", accessor: "redemptions" },
//     { header: "Published", accessor: "published" }

const columnMap = [""];

export const VirtualGrid = ({ height, width, table }) => {
  const [tableData] = useTableViewModelContext();

  // const handleSelect = useCallback(() => {
  //   document.getElementById(`${table}_checkbox${id}`).click();
  // }, [tableData]);

  const Cell = useCallback(
    ({ columnIndex, rowIndex, style }) => {
      const backgroundColor = rowIndex % 2 === 1 ? colors.tableOdd : colors.tableEven;
      console.log("wtf");
      style = {
        ...style,
        backgroundColor,
        display: "flex",
        alignItems: "center",
        borderLeft: "1px solid white",
        borderRight: "1px solid white",
        padding: "0 15px",
        boxSizing: "borderBox",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
      };
      const row = tableData[table].rows[rowIndex].node;
      const column = tableData[table].columns[columnIndex];
      let value = "";
      if (typeof column.accessor === "function") {
        value = column.accessor(row);
      } else {
        value = row[column.accessor];
      }
      console.log(column);
      if (column.link) {
        console.log(column);
        if (Array.isArray(column.link)) {
          console.log(column);
          return (
            <div style={style}>
              {column.link.map((link, idx) => (
                <CellLink onClick={(e) => e.stopPropagation()} to={link}>
                  {console.log(value)}
                  {`${value[idx]}${idx < value.length - 1 ? ", " : ""}`}
                </CellLink>
              ))}
            </div>
          );
        } else {
          return (
            <div style={style}>
              <CellLink onClick={(e) => e.stopPropagation()} to={column.link(row)}>
                {value}
              </CellLink>
            </div>
          );
        }
      } else {
        return <div style={style}>{value}</div>;
      }
    },
    [tableData]
  );

  return (
    <Grid
      columnCount={tableData[table].columns.length}
      columnWidth={() => 100}
      height={height}
      rowCount={tableData[table].rows.length}
      rowHeight={() => 40}
      width={width}
    >
      {Cell}
    </Grid>
  );
};

// columns.map((column) => {
//   let value = "";
//   if (typeof column.accessor === "function") {
//     value = column.accessor(row);
//   } else {
//     value = row[column.accessor];
//   }

// const [tableData] = useTableViewModelContext();

//   return (
//     <>
//       {tableData[table].rows.map((row, idx) => {
//         return (
//           <TableRow
//             key={row.node[tableData[table].identifier]}
//             table={table}
//             columns={tableData[table].columns}
//             id={row.node[tableData[table].identifier]}
//             row={row.node}
//             index={idx}
//             isSelectable={areRowsSelectable}
//           />
//         );
//       })}
//     </>
//   );
// };
