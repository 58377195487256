import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { OfferDetails, VenueDetails } from ".";
import { Loader } from "../../loaders";
import { Divider, Flex, Section } from "../../shared";
import { useSearchContext } from "../searchContext";
import { isPastDate } from "../../../utility";
import { useAnchorContext } from "../";

const GET_VENUES = gql`
  query {
    allActiveVenuesAndOffers {
      edges {
        node {
          venueId
          phone
          website
          address
          city
          state
          zipCode
          logo
          name
          area
          offers {
            offerId
            endDate
            header
            subheader
            description
            disclaimer
            offerType
            image
          }
          offerDays {
            offerId
            day
          }
        }
      }
    }
  }
`;

export const MemberOffer = () => {
  const { loading, error, data } = useQuery(GET_VENUES, { fetchPolicy: "no-cache" });
  const [searchData] = useSearchContext();
  const [venuesAndOffers, setVenuesAndOffers] = useState([]);

  const [anchorData] = useAnchorContext();
  const [anchoring, setAnchoring] = useState(anchorData.anchor ? true : false);

  useEffect(() => {
    if (!loading && anchorData.anchor && venuesAndOffers.length > 0) {
      const offerIds = venuesAndOffers.reduce((acc, curr) => {
        return acc.concat(curr.node.offers.map((offer) => offer.offerId));
      }, []);
      if (offerIds.includes(anchorData.anchor)) {
        const interval = setInterval(() => {
          const anchor = document.getElementById(anchorData.anchor);
          if (anchor) {
            setTimeout(() => {
              setAnchoring(false);
            }, 500);
            window.clearInterval(interval);
          }
        }, 50);
      } else {
        setAnchoring(null);
      }
    }
  }, [anchorData.anchor, loading, setAnchoring, venuesAndOffers]);

  useEffect(() => {
    if (anchorData.anchor && anchoring === false) {
      const anchor = document.getElementById(anchorData.anchor);
      anchor.scrollIntoView();
    }
  }, [anchoring, anchorData]);

  useEffect(() => {
    const venuesOffers = [];
    if (data) {
      data.allActiveVenuesAndOffers.edges.forEach((edge) => {
        let isValidNode = true;
        let filteredOffers = [];
        const offers = Array.from(edge.node.offers);
        const offerDays = Array.from(edge.node.offerDays).reduce((acc, curr) => {
          if (acc[curr.offerId]) {
            acc[curr.offerId].push(curr.day);
          } else {
            acc[curr.offerId] = [curr.day];
          }
          return acc;
        }, {});
        filteredOffers = offers.filter((offer) => {
          if (offer.endDate && isPastDate(new Date(offer.endDate))) {
            return false;
          }
          const offerType = JSON.parse(offer.offerType).value;
          const validTypes = searchData.filter.offerType.map((ot) => ot.value);
          if (validTypes.length > 0 && !validTypes.includes(offerType)) {
            return false;
          }
          let hasValidDay = true;
          searchData.daysAvailable.forEach((dayFilter) => {
            if (!offerDays[offer.offerId].includes(dayFilter.value)) {
              hasValidDay = false;
            }
          });
          if (!hasValidDay) {
            return false;
          }
          return true;
        });
        if (filteredOffers.length === 0) {
          isValidNode = false;
        }
        if (searchData.filter.venueName) {
          if (edge.node.name.search(new RegExp(searchData.filter.venueName.value, "i")) === -1) {
            isValidNode = false;
          }
        }
        if (searchData.filter.venueArea) {
          const areas = searchData.filter.venueArea.map((va) => va.value);
          if (areas.length > 0 && !areas.includes(edge.node.area)) {
            isValidNode = false;
          }
        }
        if (searchData.filter.venueCity) {
          const cities = searchData.filter.venueCity.map((vc) => vc.value);
          if (cities.length > 0 && !cities.includes(edge.node.city)) {
            isValidNode = false;
          }
        }
        if (isValidNode) {
          const venueAndOffers = Object.assign({}, edge);
          venueAndOffers.node.offers = filteredOffers;
          venuesOffers.push(venueAndOffers);
        }
      });
      setVenuesAndOffers(venuesOffers);
      if (searchData.filter.hasBeenClicked) {
        if (venuesOffers.length > 0) {
          toast.success(`Found ${venuesOffers.length} offer(s) below`, {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false,
            autoClose: 2500
          });
        } else {
          toast.error(`No venues were found`, {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false,
            autoClose: 2500
          });
        }
      }
    }
  }, [data, searchData.filter]);

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      {(loading || anchoring) && <Loader />}
      {venuesAndOffers
        .sort((a, b) => {
          if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
          if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
          return 0;
        })
        .map((edge) => (
          <Section key={edge.node.venueId}>
            <VenueDetails venue={edge.node} />
            <Divider />
            <Flex>
              {edge.node.offers.map((offer, idx) => {
                const endDate = new Date(offer.endDate);
                if (endDate < new Date()) {
                  return <React.Fragment key={offer.offerId}></React.Fragment>;
                }
                return (
                  <React.Fragment key={offer.offerId}>
                    <OfferDetails key={offer.offerId} venueId={edge.node.venueId} offer={offer} />
                    {idx < edge.node.offers.length - 1 && <Divider key={offer.offerId + "divider"} />}
                  </React.Fragment>
                );
              })}
            </Flex>
          </Section>
        ))}
      <ToastContainer />
    </>
  );
};
