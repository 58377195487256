import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import React, { useCallback, useEffect, useState, useReducer } from "react";
import { MdAdd, MdRemove, MdRemoveRedEye, MdSave } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { handleReject } from "../../utility";
import { Loader } from "../loaders";
import {
  Button,
  ConfirmButtonWrapper,
  ConfirmText,
  Form,
  iconSize,
  Label,
  ManagerButton,
  ManagerButtonText,
  Modal,
  ModalWrapper,
  MultipleInputWrapper,
  PasswordContainer,
  PasswordInput,
  PasswordToggle,
  RadioContainer,
  RadioInput,
  RadioLabel,
  Text,
  TextInput
} from "../shared";
import { useSelectViewModelContext, useTableViewModelContext } from "../table";

const GET_USER = gql`
  query($userId: ID) {
    user(userId: $userId) {
      address
      city
      email
      firstName
      lastName
      notes
      phone
      state
      userType
      userStatus
      zipCode
      created
      lastUpdate
      password
    }
  }
`;

const DELETE_USERS = gql`
  mutation($ids: [ID]!) {
    deleteUsers(ids: $ids) {
      success
    }
  }
`;

const CREATE_USER = gql`
  mutation(
    $address: String
    $city: String
    $email: String!
    $firstName: String!
    $lastName: String!
    $notes: String
    $password: String!
    $phone: String
    $state: String
    $userStatus: String!
    $userType: String!
    $zipCode: String
    $created: String!
  ) {
    createUser(
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      notes: $notes
      password: $password
      phone: $phone
      created: $created
      state: $state
      userStatus: $userStatus
      userType: $userType
      zipCode: $zipCode
    ) {
      userId
    }
  }
`;

const UPDATE_USER = gql`
  mutation($changes: UserInput!, $userId: ID!) {
    updateUser(changes: $changes, userId: $userId) {
      user {
        email
        firstName
        lastName
        phone
        address
        city
        state
        zipCode
        notes
      }
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const LeftButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const defaultState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  userType: "member",
  userStatus: "active",
  notes: "",
  isSubmitting: false,
  dirtyFields: [],
  isModalVisible: false,
  isDeleteVisible: false
};

const memberManagerDispatch = (state, action) => {
  switch (action.type) {
    case "SET_IDENTIFIER": {
      return {
        ...state,
        identifier: action.payload
      };
    }
    case "SET_ALL": {
      return {
        ...action.payload
      };
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      if (!newState.dirtyFields.includes(action.payload.name)) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "RESET_DIRTY": {
      return {
        ...state,
        dirtyFields: []
      };
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "ADD_MEMBER": {
      return {
        ...defaultState,
        dirtyFields: []
      };
    }
    case "DUPLICATE_MEMBER": {
      return {
        ...state,
        identifier: undefined
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SHOW_DELETE_MODAL": {
      return {
        ...state,
        isDeleteVisible: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const MemberManager = ({ identifier }) => {
  const [state, componentDispatch] = useReducer(
    memberManagerDispatch,
    Object.assign({}, defaultState, { identifier })
  );
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { userId: state.identifier },
    fetchPolicy: "no-cache"
  });
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USERS);
  const [selectData, selectDispatch] = useSelectViewModelContext();
  const [tableData, tableDispatch] = useTableViewModelContext();
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordType = useCallback(() => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }, [passwordType, setPasswordType]);

  useEffect(() => {
    if (state.identifier && data && data.user) {
      const { user } = data;
      componentDispatch({
        type: "SET_ALL",
        payload: Object.assign({ ...state }, { ...user })
      });
    }
  }, [state.identifier, data]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const hideModal = useCallback(() => {
    componentDispatch({
      type: "SHOW_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const showDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: true
    });
  }, [componentDispatch]);

  const hideDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const handleDelete = useCallback(() => {
    if (identifier) {
      const ids = [identifier];
      deleteUser({
        variables: {
          ids: ids
        }
      }).then(
        () => {
          toast.success("Deleted successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          selectDispatch({
            type: "DELETE_IDS",
            payload: { table: "member", ids: [identifier] }
          });
          tableDispatch({
            type: "DELETE_IDS",
            payload: { table: "member", ids: [identifier] }
          });
          addMember();
        },
        (reject) => handleReject(reject, "A problem occurred while deleting this record.")
      );
    }
    hideDelete();
  }, [selectData, tableData, identifier]);

  const addMember = useCallback(() => {
    componentDispatch({
      type: "ADD_MEMBER"
    });
    toast.success("New member is ready to be created.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, [componentDispatch]);

  // const duplicateMember = useCallback(() => {
  //   componentDispatch({
  //     type: "DUPLICATE_MEMBER"
  //   });
  // }, [componentDispatch]);

  const saveMember = useCallback(() => {
    if (state.identifier) {
      const changes = {};
      state.dirtyFields.map((field) => {
        changes[field] = state[field];
      });
      changes["lastUpdate"] = new Date().toString();
      componentDispatch({ type: "START_SAVE" });
      updateUser({
        variables: {
          changes,
          userId: state.identifier
        }
      })
        .then(() => {
          refetch();
          toast.success("Updated successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    } else {
      componentDispatch({ type: "START_SAVE" });
      createUser({
        variables: {
          email: state.email,
          password: state.password,
          firstName: state.firstName,
          lastName: state.lastName,
          phone: state.phone,
          address: state.address,
          city: state.city,
          state: state.state,
          zipCode: state.zipCode,
          userType: state.userType,
          userStatus: state.userStatus,
          notes: state.notes,
          created: new Date().toString()
        }
      })
        .then((response) => {
          componentDispatch({
            type: "SET_IDENTIFIER",
            payload: response.data.createUser.userId
          });
          toast.success("Created successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    }
  }, [state, componentDispatch, updateUser, createUser]);

  const saveThenAddMember = useCallback(() => {
    saveMember();
    addMember();
  }, [addMember, saveMember]);

  const handleAddClick = useCallback(() => {
    if (state.dirtyFields.length > 0) {
      componentDispatch({
        type: "SHOW_MODAL",
        payload: true
      });
    } else {
      addMember();
    }
  }, [state.dirtyFields, componentDispatch]);

  if (error) {
    return <div>{error.message}</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMember();
  };
  return (
    <>
      {(loading || state.isSubmitting) && <Loader text={state.isSubmitting && "Saving..."} />}
      <TopBar>
        <LeftButtons>
          <ManagerButton type="button" onClick={handleAddClick}>
            <MdAdd size={iconSize} />
            <ManagerButtonText>New</ManagerButtonText>
          </ManagerButton>
          {/* <ManagerButton type="button" onClick={duplicateMember}>
            <MdContentCopy size={iconSize} />
            <ManagerButtonText>Duplicate</ManagerButtonText>
          </ManagerButton> */}
        </LeftButtons>
        <Header>Member Manager</Header>
        <RightButtons>
          <ManagerButton form="member_manager_form" disabled={state.dirtyFields.length === 0}>
            <MdSave size={iconSize} />
            <ManagerButtonText>Save</ManagerButtonText>
          </ManagerButton>
          <ManagerButton disabled={state.identifier === undefined} onClick={showDelete}>
            <MdRemove size={iconSize} />
            <ManagerButtonText>Delete</ManagerButtonText>
          </ManagerButton>
        </RightButtons>
      </TopBar>
      <Form id="member_manager_form" onSubmit={handleSubmit}>
        <Label>
          <Text>Created</Text>
          <TextInput
            disabled
            value={`${
              state.created
                ? moment(new Date(state.created)).format("M/D/YY hh:mm A")
                : "Has not been created"
            }`}
          />
        </Label>
        <Label>
          <Text>Last Update</Text>
          <TextInput
            disabled
            value={`${
              state.lastUpdate
                ? moment(new Date(state.lastUpdate)).format("M/D/YY hh:mm A")
                : "Has not been updated"
            }`}
          />
        </Label>
        <MultipleInputWrapper required>
          <Text>Status</Text>
          <RadioLabel>
            <RadioInput
              name="userStatus"
              value="active"
              onChange={handleChange}
              checked={state.userStatus === "active"}
            />
            <span>Active</span>
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              name="userStatus"
              value="inactive"
              onChange={handleChange}
              checked={state.userStatus === "inactive"}
            />
            <span>Inactive</span>
          </RadioLabel>
        </MultipleInputWrapper>
        <MultipleInputWrapper required>
          <Text>User</Text>
          <RadioContainer>
            <RadioLabel>
              <RadioInput
                name="userType"
                value="member"
                onChange={handleChange}
                checked={state.userType === "member"}
              />
              <span>Member</span>
            </RadioLabel>
            <RadioLabel>
              <RadioInput
                name="userType"
                value="admin"
                onChange={handleChange}
                checked={state.userType === "admin"}
              />
              <span>Administrator</span>
            </RadioLabel>
          </RadioContainer>
        </MultipleInputWrapper>
        <Label required>
          <Text>Email Address</Text>
          <TextInput required name="email" value={state.email} onChange={handleChange} />
        </Label>
        <Label required>
          <Text>Password</Text>
          <PasswordContainer>
            <PasswordInput
              required
              type={passwordType}
              name="password"
              value={state.password}
              onChange={handleChange}
            />
            <PasswordToggle onClick={togglePasswordType}>
              <MdRemoveRedEye />
            </PasswordToggle>
          </PasswordContainer>
        </Label>
        <Label required>
          <Text>First Name</Text>
          <TextInput required name="firstName" value={state.firstName} onChange={handleChange} />
        </Label>
        <Label required>
          <Text>Last Name</Text>
          <TextInput required name="lastName" value={state.lastName} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Phone</Text>
          <TextInput name="phone" value={state.phone} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Address</Text>
          <TextInput name="address" value={state.address} onChange={handleChange} />
        </Label>
        <Label>
          <Text>City</Text>
          <TextInput name="city" value={state.city} onChange={handleChange} />
        </Label>
        <Label>
          <Text>State</Text>
          <TextInput name="state" value={state.state} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Zip Code</Text>
          <TextInput name="zipCode" value={state.zipCode} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Notes</Text>
          <TextInput name="notes" value={state.notes} onChange={handleChange} />
        </Label>
      </Form>
      {state.isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Add member and...</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={saveThenAddMember}>Save Current Changes</Button>
              <Button onClick={addMember}>Just Add Member</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      {state.isDeleteVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Are you sure you want to delete this user?</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Delete</Button>
              <Button onClick={hideDelete}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};
