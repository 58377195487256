import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MemberWelcome, MemberWhatsUp, VenueSearch, MemberOffer, OfferStats, useAnchorContext } from ".";
import brewSaverLogo from "../../assets/brewsaver-logo-bubbles.png";
import { Section } from "../shared";
import { Loader } from "../loaders";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getCookie } from "../../utility";

const GET_USER_FROM_COOKIE = gql`
  query($cookie: String!) {
    userFromCookie(cookie: $cookie) {
      address
      city
      email
      firstName
      lastName
      notes
      phone
      state
      userType
      userStatus
      zipCode
    }
  }
`;

const Logo = styled.img`
  max-width: 95%;
  margin-bottom: 10px;
`;

export const MemberHome = () => {
  const { loading, error, data } = useQuery(GET_USER_FROM_COOKIE, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });

  if (error) {
    Object.keys(error).map((key) => console.warn(key, error[key]));

    return <div>{error.message}</div>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Logo src={brewSaverLogo} alt="brew saver logo" />
      <Section>
        <MemberWelcome first={data.userFromCookie.firstName} last={data.userFromCookie.lastName} />
      </Section>
      <OfferStats />
      <MemberOffer />
    </>
  );
};
