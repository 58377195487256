// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRange } from "react-date-range";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import React, { useCallback, useEffect, useReducer } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { ButtonLinkStyles, SectionHeader, StyledSelect } from "../shared";
import { useSearchContext } from "./searchContext";
import { colors } from "../../colors";

const GET_VENUES = gql`
  query {
    allActiveVenues {
      venueId
      name
      city
    }
  }
`;

const GET_VENUE_AREAS = gql`
  query {
    allVenueAreas {
      venueAreaId
      text
    }
  }
`;

const GET_OFFER_TYPES = gql`
  query {
    allOfferTypes {
      offerTypeId
      text
    }
  }
`;

const MenuSelect = styled(StyledSelect)`
  width: 100%;
  margin: 0;
  min-width: unset;
  max-width: unset;
  color: black;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 0 auto 8px auto;
  color: ${colors.secondaryOffset};
`;

const LabelText = styled.span`
  font-weight: 600;
  font-size: 15px;
`;

const Search = styled.button`
  ${ButtonLinkStyles}
  width: 90%;
  font-size: 18px;
  margin: 10px auto 0 auto;
`;

const dayDropdownOptions = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" }
];

const sortAlphabetical = (a, b) => {
  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
  return 0;
};

export const VenueSearchMenu = ({ onSearch }) => {
  const [searchData, searchDispatch] = useSearchContext();
  const venuesQuery = useQuery(GET_VENUES, { fetchPolicy: "no-cache" });
  const venueAreasQuery = useQuery(GET_VENUE_AREAS, { fetchPolicy: "no-cache" });
  const offerTypesQuery = useQuery(GET_OFFER_TYPES, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (venuesQuery.data) {
      const venueNames = [];
      const cities = [];
      let nameOptions = [];
      let cityOptions = [];
      venuesQuery.data.allActiveVenues.forEach((venue) => {
        if (!venueNames.includes(venue.name)) {
          nameOptions.push({ value: venue.name, label: venue.name });
          venueNames.push(venue.name);
        }
        if (!cities.includes(venue.city)) {
          cityOptions.push({ value: venue.city, label: venue.city });
          cities.push(venue.city);
        }
      });
      nameOptions = nameOptions.sort(sortAlphabetical);
      cityOptions = cityOptions.sort(sortAlphabetical);
      searchDispatch({
        type: "SET_ONE",
        payload: { name: "names", value: nameOptions }
      });
      searchDispatch({
        type: "SET_ONE",
        payload: { name: "cities", value: cityOptions }
      });
    }
  }, [venuesQuery, searchDispatch]);

  useEffect(() => {
    if (venueAreasQuery.data) {
      let areaNames = [];
      venueAreasQuery.data.allVenueAreas.forEach((area) => {
        areaNames.push({ value: area.text, label: area.text });
      });
      areaNames = areaNames.sort(sortAlphabetical);
      searchDispatch({
        type: "SET_ONE",
        payload: { name: "areas", value: areaNames }
      });
    }
  }, [venueAreasQuery, searchDispatch]);

  useEffect(() => {
    if (offerTypesQuery.data) {
      let offerTypeOptions = [];
      offerTypesQuery.data.allOfferTypes.forEach((offerType) => {
        offerTypeOptions.push({ value: offerType.text, label: offerType.text });
      });
      offerTypeOptions = offerTypeOptions.sort(sortAlphabetical);
      searchDispatch({
        type: "SET_ONE",
        payload: { name: "offerTypes", value: offerTypeOptions }
      });
    }
  }, [offerTypesQuery, searchDispatch]);

  // const handleDateRangeSelect = useCallback((ranges) => {
  //   setDatesAvailable([ranges]);
  // }, []);

  const handleSelect = useCallback((value, props) => {
    if (value === null) {
      value = [];
    }
    searchDispatch({
      type: "SET_ONE",
      payload: { name: props.name, value }
    });
  });

  const handleSearch = useCallback(() => {
    const values = {
      venueName: searchData.venueName,
      venueArea: searchData.venueArea,
      venueCity: searchData.venueCity,
      offerType: searchData.offerType,
      daysAvailable: searchData.daysAvailable,
      hasBeenClicked: true
    };
    searchDispatch({
      type: "SET_SEARCH_VALUES",
      payload: { ...values }
    });
    if (onSearch) {
      onSearch();
    }
  }, [searchData, searchDispatch]);

  return (
    <>
      <Label>
        <LabelText>Venue</LabelText>
        <MenuSelect
          name="venueName"
          value={searchData.venueName}
          onChange={handleSelect}
          options={searchData.names}
          isClearable
          placeholder="<All>"
          maxMenuHeight={80}
        />
      </Label>
      <Label>
        <LabelText>Area</LabelText>
        <MenuSelect
          name="venueArea"
          options={searchData.areas}
          isMulti
          isSearchable
          value={searchData.venueArea}
          onChange={handleSelect}
          placeholder="<All>"
          maxMenuHeight={80}
        />
      </Label>
      <Label>
        <LabelText>City</LabelText>
        <MenuSelect
          name="venueCity"
          options={searchData.cities}
          isMulti
          isSearchable
          value={searchData.venueCity}
          onChange={handleSelect}
          placeholder="<All>"
          maxMenuHeight={80}
        />
      </Label>
      <Label>
        <LabelText>Offer Type</LabelText>
        <MenuSelect
          name="offerType"
          options={searchData.offerTypes}
          isMulti
          isSearchable
          value={searchData.offerType}
          onChange={handleSelect}
          placeholder="<All>"
          maxMenuHeight={80}
        />
      </Label>
      <Label>
        <LabelText>Days Available</LabelText>
        <MenuSelect
          name="daysAvailable"
          options={dayDropdownOptions}
          isMulti
          isSearchable
          value={searchData.daysAvailable}
          onChange={handleSelect}
          placeholder="<All>"
          maxMenuHeight={80}
        />
      </Label>
      {/* <Label>
        Dates Available
        <DateRange name="datesAvailable" ranges={datesAvailable} onChange={handleDateRangeSelect} plaeholder="All" />
      </Label> */}
      <Link to="/member" onClick={handleSearch} style={{ textDecoration: "none" }}>
        <Search>Search</Search>
      </Link>
    </>
  );
};
