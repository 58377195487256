import React, { createContext, useContext, useReducer } from "react";
import gql from "graphql-tag";
import { getCookie, getZeroRedemptionOfferValidationMessage } from "../../utility";
import moment from "moment";
import _ from "lodash";
import { OfferTypeCell } from "../../components";

export const tableViewModel = createContext();
tableViewModel.displayName = "Table Context";
export const useTableViewModelContext = () => {
  return useContext(tableViewModel);
};

export const columns = {
  member: [
    {
      header: "Name",
      accessor: (row) => `${row.firstName} ${row.lastName}`,
      link: (row) => `/admin/manage/member/${row.userId}`
    },
    { header: "Email", accessor: "email" },
    //{ header: "City", accessor: "city" },
    { header: "Status", accessor: "userStatus" },
    { header: "Role", accessor: "userType" },
    {
      header: "Redemptions",
      accessor: (row) => row.redemptions && row.redemptions.length,
      link: (row) => `/admin/table/redemption/Member/${row.firstName} ${row.lastName}`,
      style: { justifyContent: "center" }
    },
    { header: "Created", accessor: (row) => moment(row.created).format("M/D/YY HH:mm") }
  ],
  client: [
    {
      header: "Name",
      accessor: (row) => `${row.firstName} ${row.lastName}`,
      link: (row) => {
        return `/admin/manage/client/${row.userId}`;
      }
    },
    { header: "Email", accessor: "email" },
    {
      header: "Venue",
      accessor: (row) => row.venues.map((venue) => venue.label),
      link: (row) => row.venues.map((venue) => `/admin/manage/venue/${venue.value}`)
    },
    { header: "Status", accessor: "userStatus" },
    { header: "Created", accessor: (row) => moment(row.created).format("M/D/YY HH:mm") }
  ],
  venue: [
    {
      header: "Name",
      accessor: "name",
      link: (row) => `/admin/manage/venue/${row.venueId}`
    },
    { header: "Area", accessor: "area" },
    { header: "Status", accessor: "status" },
    {
      header: "Clients",
      accessor: (row) => {
        return row.users.map((user) => `${user.firstName.substr(0, 1)} ${user.lastName}`);
      },
      link: (row) => row.users.map((user) => `/admin/manage/client/${user.userId}`),
      style: { justifyContent: "center" }
    },
    {
      header: "Offers",
      accessor: (row) => {
        return row.venueOffers && row.venueOffers.length;
      },
      link: (row) => `/admin/table/offer/Venue/${row.name}`,
      style: { justifyContent: "center" }
    },
    {
      header: "Redeemed",
      accessor: (row) => row.redemptions && row.redemptions.length,
      link: (row) => `/admin/table/redemption/Venue/${row.name}`,
      style: { justifyContent: "center" }
    },
    { header: "Created", accessor: (row) => moment(row.created).format("M/D/YY HH:mm") }
  ],
  offer: [
    { header: "Venue", accessor: (row) => row.venues && row.venues[0] && row.venues[0].label },
    {
      header: "Offer Type",
      accessor: (row) => `${JSON.parse(row.offerType).label}`,
      link: (row) => `/admin/manage/offer/${row.offerId}`
    },
    { header: "Status", accessor: (row) => getZeroRedemptionOfferValidationMessage(row).message },
    {
      header: "Redemptions",
      accessor: (row) => row.redemptions && row.redemptions.length,
      link: (row) => `/admin/table/redemption/Offer/${JSON.parse(row.offerType).label}`
    },
    { header: "Published", accessor: (row) => moment(row.published).format("M/D/YY HH:mm") }
  ],
  redemption: [
    {
      header: "Member",
      accessor: (row) => (row.user ? `${row.user.firstName} ${row.user.lastName}` : ""),
      link: (row) => `/admin/manage/member/${row.userId}`
    },
    {
      header: "Venue",
      accessor: (row) => (row.venue && row.venue.name) || "Deleted",
      link: (row) => `/admin/manage/venue/${row.venueId}`
    },
    {
      header: "Offer",
      accessor: (row) => (row.offer && JSON.parse(row.offer.offerType).label) || "Deleted",
      link: (row) => `/admin/manage/offer/${row.offerId}`
    },
    { header: "Date", accessor: "date" },
    { header: "Time", accessor: (row) => moment(row.time, "h:m:s").format("hh:mm A") }
  ],
  clientRedemption: [
    {
      header: "Venue",
      accessor: (row) => (row.venues && row.venues[0] && row.venues[0].label) || "Deleted",
      link: (row) =>
        row.venues && row.venues[0] && row.venues[0].value
          ? `/client/manage/venue/${row.venues[0].value}`
          : undefined
    },
    {
      header: "Offer Type",
      accessor: (row) => {
        let offer;
        if (row.offers?.length > 0) {
          offer = row.offers[0];
        }
        offer.venues = row.venues;
        offer.offerId = offer.Id;
        if (offer) {
          return <OfferTypeCell offer={offer} />;
        }
        return "Not Found / Deleted";
      }
    },
    { header: "Member", accessor: (row) => `${row.firstName} ${row.lastName}` },
    { header: "Email", accessor: "email" },
    { header: "Redemption Date", accessor: "date" },
    { header: "Redemption Time", accessor: (row) => moment(row.time, "h:m:s").format("hh:mm A") }
  ]
};

const memberQuery = gql`
  query {
    membersAndAdmins {
      edges {
        node {
          userId
          firstName
          lastName
          email
          city
          userStatus
          userType
          created
          redemptions {
            redemptionId
          }
        }
      }
    }
  }
`;

const clientQuery = gql`
  query {
    clients {
      edges {
        node {
          userId
          firstName
          lastName
          email
          city
          userStatus
          created
          venues {
            value
            label
          }
        }
      }
    }
  }
`;

const venueQuery = gql`
  query {
    venues {
      edges {
        node {
          venueId
          name
          status
          created
          area
          users {
            userId
            firstName
            lastName
          }
          venueOffers {
            venueOfferId
            offerId
          }
          redemptions {
            redemptionId
          }
        }
      }
    }
  }
`;

const offerQuery = gql`
  query {
    offers {
      edges {
        node {
          offerId
          offerType
          offerStatus
          published
          startDate
          endDate
          timeValidStart
          timeValidEnd
          validDays
          venues {
            value
            label
          }
          redemptions {
            redemptionId
          }
        }
      }
    }
  }
`;

const redemptionQuery = gql`
  query {
    allRedemptions {
      edges {
        node {
          redemptionId
          userId
          venueId
          offerId
          date
          time
          user {
            firstName
            lastName
          }
          venue {
            name
          }
          offer {
            offerType
          }
        }
      }
    }
  }
`;

const clientRedemptionQuery = gql`
  query($userCookie: String!) {
    clientRedemption(userCookie: $userCookie) {
      edges {
        node {
          redemptionId
          date
          time
          firstName
          lastName
          userType
          email
          venues {
            value
            label
          }
          offers {
            Id
            offerId
            header
            description
            disclaimer
            image
            startDate
            endDate
            maxValue
            offerType
            offerStatus
            published
            usesPerDay
          }
        }
      }
    }
  }
`;

const defaultState = {
  member: {
    query: memberQuery,
    accessor: "membersAndAdmins",
    identifier: "userId"
  },
  client: {
    query: clientQuery,
    accessor: "clients",
    identifier: "userId"
  },
  venue: {
    query: venueQuery,
    accessor: "venues",
    identifier: "venueId"
  },
  offer: {
    query: offerQuery,
    accessor: "offers",
    identifier: "offerId"
  },
  redemption: {
    query: redemptionQuery,
    accessor: "allRedemptions",
    identifier: "redemptionId"
  },
  clientRedemption: {
    query: clientRedemptionQuery,
    variables: { userCookie: getCookie("uuid") },
    accessor: "clientRedemption",
    identifier: "redemptionId",
    title: "Redemption Table"
  }
};

// tables const with all table names / attributes
const TABLES = {};

Object.keys(defaultState).forEach((key) => {
  defaultState[key].columns = columns[key];
  defaultState[key].sortBy = undefined;
  defaultState[key].sortOrder = 1; // 1 for asc, -1 for desc
  defaultState[key].rows = [];
  defaultState[key].filtered = [];
  defaultState[key].isFilterVisible = false;
  defaultState[key].isLoading = false;
});

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_LOADING": {
      const { table, isLoading } = action.payload;
      const newState = { ...state };
      newState[table].isLoading = isLoading;
      return newState;
    }
    case "SORT_BY": {
      const { table, accessor } = action.payload;
      const newState = { ...state };
      if (newState[table].sortBy === accessor) {
        newState[table].sortOrder *= -1;
      } else {
        newState[table].sortOrder = 1; // asc
      }
      newState[table].sortBy = accessor;
      // do the sorting here
      const sortedData = Array.from(
        newState[table].rows.sort((a, b) => {
          if (typeof accessor === "function") {
            return accessor(a.node) > accessor(b.node)
              ? 1 * newState[table].sortOrder
              : -1 * newState[table].sortOrder;
          } else {
            return a.node[accessor] > b.node[accessor]
              ? 1 * newState[table].sortOrder
              : -1 * newState[table].sortOrder;
          }
        })
      );
      const sortedFilter = Array.from(
        newState[table].filtered.sort((a, b) => {
          if (typeof accessor === "function") {
            return accessor(a.node) > accessor(b.node)
              ? 1 * newState[table].sortOrder
              : -1 * newState[table].sortOrder;
          } else {
            return a.node[accessor] > b.node[accessor]
              ? 1 * newState[table].sortOrder
              : -1 * newState[table].sortOrder;
          }
        })
      );
      newState[table].rows = sortedData;
      newState[table].filtered = sortedFilter;
      // newState[table].isLoading = false;
      return newState;
    }
    case "SET_TABLE_ROWS": {
      const { table, rows } = action.payload;
      const newState = { ...state };
      newState[table].rows = [...rows];
      newState[table].filtered = [...rows];
      return newState;
    }
    case "SET_IDS_COLUMN": {
      const { table, ids, column, value } = action.payload;
      const newState = { ...state };
      newState[table].rows.forEach((row, idx) => {
        if (ids.includes(row.node[newState[table].identifier])) {
          row.node[column] = value;
        }
      });
      return newState;
    }
    case "DELETE_IDS": {
      const { table, ids } = action.payload;
      const newState = { ...state };
      newState[table].rows = newState[table].rows.filter(
        (row) => !ids.includes(row.node[newState[table].identifier])
      );
      newState[table].filtered = newState[table].filtered.filter(
        (row) => !ids.includes(row.node[newState[table].identifier])
      );
      return newState;
    }
    case "FILTER_DATA": {
      const { table, filters } = action.payload;
      console.log(filters);
      const newState = { ...state };
      let filtered = [...newState[table].rows];
      filtered = filtered.filter((row) => {
        let showRow = true;
        columns[table].forEach((col) => {
          let value;
          if (typeof col.accessor === "function") {
            value = col.accessor(row.node);
          } else {
            value = row.node[col.accessor];
          }
          const searchVal = new RegExp(filters[col.header], "i");
          if (col.header === "Status") {
          }
          if ((value || value === 0 || value === "") && value.toString().search(searchVal) === -1) {
            showRow = false;
          }
        });
        return showRow;
      });
      console.log(filtered);
      newState[table].filtered = filtered;
      return newState;
    }
    case "RESET_DATA": {
      const table = action.payload;
      const newState = { ...state };
      newState[table].filtered = [...newState[table].rows];
      return newState;
    }
    case "CLEAR_REDEMPTIONS": {
      const { table, ids } = action.payload;
      const newState = _.cloneDeep(state);
      newState[table].rows.forEach((row, idx) => {
        if (ids.includes(row.node[newState[table].identifier])) {
          newState[table].rows[idx].redemptions = [];
        }
      });
      newState[table].filtered.forEach((row, idx) => {
        if (ids.includes(row.node[newState[table].identifier])) {
          newState[table].filtered[idx].redemptions = [];
        }
      });
      return newState;
    }
    default:
      return { ...state };
  }
};

export const TableProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <tableViewModel.Provider value={[state, dispatch]}>{children}</tableViewModel.Provider>;
};
