import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useReducer } from "react";
import { MdAdd, MdContentCopy, MdRemove, MdSave } from "react-icons/md";
import styled from "styled-components";
import { ImageUpload } from "../";
import { colors } from "../../colors";
import { useHistory } from "react-router-dom";
import { getCookie, handleReject } from "../../utility";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  ButtonStyles,
  CheckboxInput,
  ConfirmButtonWrapper,
  ConfirmText,
  Form,
  InlineRadioWrapper,
  Label,
  Loader,
  ManagerButton,
  ManagerWrapper,
  ManagerButtonText,
  Modal,
  ModalHeader,
  ModalWrapper,
  MultipleInputLabel,
  MultipleInputText,
  MultipleInputWrapper,
  NumberInput,
  RadioText,
  StyledCreatableSelect,
  StyledDatePicker,
  StyledSelect,
  StyledTimePicker,
  Text,
  TextInput
} from "../shared";
import { useSelectViewModelContext, useTableViewModelContext } from "../table";

const DELETE_OFFER_TYPE = gql`
  mutation($offerTypeId: ID!) {
    deleteOfferType(offerTypeId: $offerTypeId) {
      success
    }
  }
`;

const DELETE_OFFERS = gql`
  mutation($ids: [ID]!) {
    deleteOffers(ids: $ids) {
      success
    }
  }
`;

const GET_OFFER = gql`
  query($offerId: ID) {
    offer(offerId: $offerId) {
      offerStatus
      offerType
      header
      subheader
      image
      description
      disclaimer
      maxValue
      allowedRedemptions
      redemptionPeriod
      usesPerDay
      resetDay
      resetTime
      startDate
      endDate
      published
      validDays
      timeValidStart
      timeValidEnd
      venues {
        value
        label
      }
    }
  }
`;

const GET_VENUE_DROPDOWN = gql`
  query {
    allVenues {
      venueId
      name
    }
  }
`;

const CREATE_OFFER = gql`
  mutation(
    $userCookie: String!
    $offerStatus: String!
    $offerType: String!
    $header: String!
    $subheader: String!
    $disclaimer: String!
    $image: String!
    $description: String!
    $maxValue: Int!
    $validDays: [Int]!
    $allowedRedemptions: Int!
    $usesPerDay: Int
    $resetDay: String!
    $venues: [ID]!
    $resetTime: String!
    $startDate: String!
    $endDate: String!
    $published: String!
    $redemptionPeriod: String!
    $timeValidStart: String!
    $timeValidEnd: String!
  ) {
    createOffer(
      userCookie: $userCookie
      offerStatus: $offerStatus
      offerType: $offerType
      header: $header
      subheader: $subheader
      image: $image
      description: $description
      maxValue: $maxValue
      validDays: $validDays
      allowedRedemptions: $allowedRedemptions
      usesPerDay: $usesPerDay
      resetDay: $resetDay
      resetTime: $resetTime
      disclaimer: $disclaimer
      timeValidStart: $timeValidStart
      timeValidEnd: $timeValidEnd
      startDate: $startDate
      endDate: $endDate
      published: $published
      venues: $venues
      redemptionPeriod: $redemptionPeriod
    ) {
      offerId
    }
  }
`;

const UPDATE_OFFER = gql`
  mutation($changes: OfferInput!, $offerId: ID!) {
    updateOffer(changes: $changes, offerId: $offerId) {
      offer {
        offerStatus
        offerType
        header
        subheader
        image
        description
        maxValue
        allowedRedemptions
        usesPerDay
        resetDay
        resetTime
        startDate
        endDate
        published
      }
    }
  }
`;

const GET_OFFER_TYPES = gql`
  query {
    allOfferTypes {
      offerTypeId
      text
    }
  }
`;

const CREATE_OFFER_TYPE = gql`
  mutation($userCookie: String!, $text: String!) {
    createOfferType(userCookie: $userCookie, text: $text) {
      offerTypeId
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const LeftButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectAllBtn = styled.input.attrs({ type: "button" })`
  ${ButtonStyles}
  background-color: ${colors.secondary};
  color: ${colors.secondaryOffset};
`;

const ManagerBtn = styled(Button)`
  width: 90%;
  margin-top: 5px;
`;

const OfferTypeList = styled.ul`
  list-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const OfferTypeSpan = styled.span``;

const OfferTypeItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
`;

const AddOfferTypeWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 90%;
`;

const iconSize = "1.5em";

const defaultState = {
  offerStatus: "",
  offerType: "",
  header: "",
  subheader: "",
  description: "",
  disclaimer: "",
  image: "",
  maxValue: "",
  allowedRedemptions: "",
  redemptionPeriod: "",
  usesPerDay: "",
  resetDay: "",
  resetTime: "",
  startDate: null,
  endDate: null,
  validDays: [],
  validAll: "Select All",
  venues: [],
  timeValidStart: "",
  timeValidEnd: "",
  isSubmitting: false,
  isOfferTypeManagerVisible: false,
  dirtyFields: [],
  newOfferType: "",
  isModalVisible: false,
  isDeleteVisible: false
};

const offerManagerDispatch = (state, action) => {
  switch (action.type) {
    case "SET_IDENTIFIER": {
      return {
        ...state,
        identifier: action.payload
      };
    }
    case "SET_ALL": {
      return {
        ...action.payload,
        validAll: action.payload.validDays.length === 7 ? "Deselect All" : "Select All"
      };
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      if (
        !newState.dirtyFields.includes(action.payload.name) &&
        newState[action.payload.name] !== state[action.payload.name] &&
        action.payload.name !== "newOfferType"
      ) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "SET_DAY": {
      const newState = { ...state };
      const dayIndex = newState.validDays.indexOf(action.payload.value);
      if (dayIndex === -1) {
        newState.validDays.push(action.payload.value);
      } else {
        newState.validDays.splice(dayIndex, 1);
      }
      if (dayIndex !== -1 && newState.validAll === "Deselect All") {
        newState.validAll = "Select All";
      }
      if (dayIndex === -1 && newState.validDays.length === 7) {
        newState.validAll = "Deselect All";
      }
      if (!newState.dirtyFields.includes(action.payload.name)) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "SET_DAYS": {
      const newState = { ...state };
      const areAllSelected = action.payload.value === "Select All";
      newState.validAll = areAllSelected ? "Deselect All" : "Select All";
      if (areAllSelected) {
        newState.validDays = ["0", "1", "2", "3", "4", "5", "6"];
      } else {
        newState.validDays = [];
      }
      if (!newState.dirtyFields.includes(action.payload.name)) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "SET_DATE": {
      const newState = { ...state };
      if (action.payload.value === null) {
        newState[action.payload.name] = action.payload.value;
      } else {
        newState[action.payload.name] = new Date(action.payload.value);
      }
      if (
        !newState.dirtyFields.includes(action.payload.name) &&
        newState[action.payload.name] !== state[action.payload.name]
      ) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "RESET_DIRTY": {
      return {
        ...state,
        dirtyFields: []
      };
    }
    case "SET_OFFER_TYPE_MANAGER_OPEN": {
      return {
        ...state,
        isOfferTypeManagerVisible: action.payload
      };
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "ADD_OFFER": {
      return {
        ...defaultState,
        identifier: undefined,
        dirtyFields: []
      };
    }
    case "DUPLICATE_OFFER": {
      return {
        ...state,
        identifier: undefined
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SHOW_DELETE_MODAL": {
      return {
        ...state,
        isDeleteVisible: action.payload
      };
    }
    default:
      return { ...state };
  }
};

const dayDropdownOptions = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" }
];

const redemptionPeriodOptions = [
  { value: "perDay", label: "Per Day" },
  { value: "perWeek", label: "Per Week" },
  { value: "perMonth", label: "Per Month" },
  { value: "perYear", label: "Per Year" }
];

export const OfferManager = ({ identifier }) => {
  const [deleteOffer] = useMutation(DELETE_OFFERS);
  const history = useHistory();
  const [state, componentDispatch] = useReducer(
    offerManagerDispatch,
    Object.assign(defaultState, { identifier: identifier })
  );
  const offerQuery = useQuery(GET_OFFER, {
    variables: { offerId: state.identifier },
    fetchPolicy: "no-cache"
  });
  const venueDropdown = useQuery(GET_VENUE_DROPDOWN, {
    fetchPolicy: "no-cache"
  });
  const [createOffer] = useMutation(CREATE_OFFER);
  const [updateOffer] = useMutation(UPDATE_OFFER);
  const [createOfferType] = useMutation(CREATE_OFFER_TYPE);
  const [deleteOfferType] = useMutation(DELETE_OFFER_TYPE);
  const offerTypesQuery = useQuery(GET_OFFER_TYPES, {
    variables: { userCookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });
  const [selectData, selectDispatch] = useSelectViewModelContext();
  const [tableData, tableDispatch] = useTableViewModelContext();

  const hideModal = useCallback(() => {
    componentDispatch({
      type: "SHOW_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const showDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: true
    });
  }, [componentDispatch]);

  const hideDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const addOffer = useCallback(() => {
    componentDispatch({
      type: "ADD_OFFER"
    });
    toast.success("New offer is ready to be created.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, [componentDispatch]);

  const duplicateOffer = useCallback(() => {
    componentDispatch({
      type: "DUPLICATE_OFFER"
    });
    toast.success("Offer has been duplicated.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, [componentDispatch]);

  const handleDelete = useCallback(() => {
    if (identifier) {
      const ids = [identifier];
      deleteOffer({
        variables: {
          ids: ids
        }
      }).then(
        () => {
          toast.success("Deleted successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          selectDispatch({
            type: "DELETE_IDS",
            payload: { table: "offer", ids: [identifier] }
          });
          tableDispatch({
            type: "DELETE_IDS",
            payload: { table: "offer", ids: [identifier] }
          });
          addOffer();
        },
        () => {
          toast.error("A problem occurred while deleting this record.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    }
    hideDelete();
  }, [selectData, tableData, identifier]);

  const saveOffer = useCallback(() => {
    const data = {
      userCookie: getCookie("uuid"),
      offerStatus: state.offerStatus,
      offerType: JSON.stringify(state.offerType),
      header: state.header,
      subheader: state.subheader,
      description: state.description,
      disclaimer: state.disclaimer,
      image: state.image,
      maxValue: parseInt(state.maxValue),
      validDays: state.validDays,
      timeValidStart: state.timeValidStart,
      timeValidEnd: state.timeValidEnd,
      allowedRedemptions: parseInt(state.allowedRedemptions),
      redemptionPeriod: JSON.stringify(state.redemptionPeriod),
      usesPerDay:
        state.redemptionPeriod === "perDay" || state.usesPerDay === "" ? null : parseInt(state.usesPerDay),
      resetDay: JSON.stringify(state.resetDay),
      resetTime: state.resetTime,
      startDate: state.startDate?.toString(),
      endDate: state.endDate?.toString(),
      published: state.published || new Date().toString(),
      venues: Array.isArray(state.venues) ? state.venues[0].value : state.venues.value
    };
    if (state.identifier) {
      componentDispatch({ type: "START_SAVE" });
      const changes = {};
      state.dirtyFields.forEach((field) => {
        switch (field) {
          case "resetDay":
          case "offerType": {
            changes[field] = JSON.stringify(state[field]);
            break;
          }
          case "venues":
            changes[field] = state[field].value;
            break;
          case "usesPerDay":
            if (state.redemptionPeriod !== "perDay" && state.usesPerDay !== "") {
              changes[field] = state[field];
            } else {
              changes[field] = null;
            }
            break;
          case "redemptionPeriod":
            changes[field] = JSON.stringify(state[field]);
            break;
          default:
            changes[field] = state[field];
            break;
        }
      });
      changes["lastUpdate"] = new Date().toString();
      updateOffer({
        variables: {
          changes,
          offerId: state.identifier
        }
      })
        .then(() => {
          toast.success("Updated successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
      componentDispatch({
        type: "RESET_DIRTY"
      });
    } else {
      componentDispatch({ type: "START_SAVE" });
      createOffer({
        variables: data
      })
        .then((response) => {
          componentDispatch({
            type: "SET_IDENTIFIER",
            payload: response.data.createOffer.offerId
          });
          toast.success("Created successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    }
  }, [state, componentDispatch, updateOffer, createOffer]);

  useEffect(() => {
    const pathSplit = history.location.pathname.split("/");
    let pathname = pathSplit.splice(0, 4).join("/");
    if (state.identifier) {
      pathname += `/${state.identifier}`;
    }
    history.push(pathname);
  }, [state.identifier]);

  const saveThenAddOffer = useCallback(() => {
    saveOffer();
    addOffer();
  }, [addOffer, saveOffer]);

  const handleAddClick = useCallback(() => {
    if (state.dirtyFields.length > 0) {
      componentDispatch({
        type: "SHOW_MODAL",
        payload: true
      });
    } else {
      addOffer();
    }
  }, [state.dirtyFields, componentDispatch]);

  useEffect(() => {
    if (state.identifier && offerQuery.data && offerQuery.data.offer) {
      const { offer } = offerQuery.data;
      Object.keys(offer).forEach((key) => {
        if (offer[key] === null) {
          offer[key] = "";
        }
      });
      offer.startDate = new Date(offer.startDate);
      offer.endDate = new Date(offer.endDate);
      offer.resetDay = JSON.parse(offer.resetDay);
      offer.offerType = JSON.parse(offer.offerType);
      offer.redemptionPeriod = JSON.parse(offer.redemptionPeriod);
      offer.venues = offer.venues;
      componentDispatch({
        type: "SET_ALL",
        payload: Object.assign({ ...state }, { ...offer })
      });
    }
  }, [state.identifier, offerQuery.data]);

  useEffect(() => {
    if (offerQuery.error && offerQuery.error.message) {
      componentDispatch({
        type: "SET_IDENTIFIER",
        payload: undefined
      });
    }
  }, [offerQuery]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveOffer();
  };

  const handleCloseManager = useCallback(() => {
    componentDispatch({
      type: "SET_OFFER_TYPE_MANAGER_OPEN",
      payload: false
    });
  }, [componentDispatch]);

  const handleManageOffers = useCallback(() => {
    componentDispatch({
      type: "SET_OFFER_TYPE_MANAGER_OPEN",
      payload: true
    });
  }, [componentDispatch]);

  const handleRemoveOfferType = useCallback(
    (offerTypeId) => {
      deleteOfferType({
        variables: { offerTypeId }
      }).then(
        () => offerTypesQuery.refetch(),
        () => {
          toast.error("A problem occurred while deleting this record.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    },
    [deleteOfferType, offerTypesQuery]
  );

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const handleSelect = useCallback((value, props) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name: props.name, value }
    });
  });

  const handleDayChange = useCallback(
    (e) => {
      if (e.target.name === "validAll") {
        componentDispatch({
          type: "SET_DAYS",
          payload: { name: "validDays", value: e.target.value }
        });
      } else {
        componentDispatch({
          type: "SET_DAY",
          payload: { name: "validDays", value: e.target.value }
        });
      }
    },
    [componentDispatch]
  );

  const handleStartDateChange = useCallback((value) => {
    componentDispatch({
      type: "SET_DATE",
      payload: { name: "startDate", value }
    });
  });

  const handleEndDateChange = useCallback((value) => {
    componentDispatch({
      type: "SET_DATE",
      payload: { name: "endDate", value }
    });
  });

  const handleValidStartTimeChange = useCallback((value) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name: "timeValidStart", value }
    });
  });

  const handleValidEndTimeChange = useCallback((value) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name: "timeValidEnd", value }
    });
  });

  const handleResetTimeChange = useCallback((value) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name: "resetTime", value }
    });
  });

  const handleUpload = useCallback((name, file) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name, value: file }
    });
  });

  const focusChildInput = useCallback((e) => {
    const input = e.target.getElementsByTagName("input")[0];
    if (input) {
      input.focus();
    }
  });

  const addOfferType = useCallback(() => {
    const offerTypes = offerTypesQuery.data.allOfferTypes.map((offerType) => offerType.text);
    if (offerTypes.indexOf(state.newOfferType) !== -1) {
      toast.error("That offer type already exists.", {
        position: "bottom-center",
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: false
      });
    } else if (state.newOfferType.length === 0) {
      toast.error("Offer type cannot be blank.", {
        position: "bottom-center",
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: false
      });
    } else {
      createOfferType({
        variables: {
          userCookie: getCookie("uuid"),
          text: state.newOfferType
        }
      }).then(
        () => offerTypesQuery.refetch(),
        () => {
          toast.error("A problem occurred while creating the offer type.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    }
  }, [state.newOfferType, offerTypesQuery]);

  const createNewOfferType = useCallback((label) => {
    const option = { value: label, label };
    if (offerTypesQuery.data.allOfferTypes.indexOf(label) === -1) {
      createOfferType({
        variables: {
          userCookie: getCookie("uuid"),
          text: label
        }
      }).then(
        () => offerTypesQuery.refetch(),
        () => {
          toast.error("A problem occurred while creating the offer type.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    }
    componentDispatch({
      type: "SET_ONE",
      payload: { name: "offerType", value: option }
    });
  });

  if (venueDropdown.error) {
    return <div>{venueDropdown.error.message}</div>;
  }

  return (
    <>
      {(offerQuery.loading || venueDropdown.loading) && <Loader />}
      <TopBar>
        <LeftButtons>
          <ManagerButton type="button" onClick={handleAddClick}>
            <MdAdd size={iconSize} />
            <ManagerButtonText>New</ManagerButtonText>
          </ManagerButton>
          <ManagerButton type="button" onClick={duplicateOffer}>
            <MdContentCopy size={iconSize} />
            <ManagerButtonText>Duplicate</ManagerButtonText>
          </ManagerButton>
        </LeftButtons>
        <Header>Offer Manager</Header>
        <RightButtons>
          <ManagerButton form="offer_manager_form" disabled={state.dirtyFields.length === 0}>
            <MdSave size={iconSize} />
            <ManagerButtonText>Save</ManagerButtonText>
          </ManagerButton>
          <ManagerButton disabled={state.identifier === undefined} onClick={showDelete}>
            <MdRemove size={iconSize} />
            <ManagerButtonText>Delete</ManagerButtonText>
          </ManagerButton>
        </RightButtons>
      </TopBar>
      <Form id="offer_manager_form" onSubmit={handleSubmit}>
        <Label disabled>
          <Text>Publish Date</Text>
          <TextInput
            disabled
            value={`${
              state.published
                ? moment(new Date(state.published)).format("M/D/YY hh:mm A")
                : "Has not been published"
            }`}
          />
        </Label>
        <Label required>
          <Text>Offer Type</Text>
          <StyledCreatableSelect
            name="offerType"
            value={state.offerType}
            onChange={handleSelect}
            options={
              offerTypesQuery.data &&
              offerTypesQuery.data.allOfferTypes.map((offerType) => ({
                value: offerType.text,
                label: offerType.text
              }))
            }
            isClearable
            onCreateOption={createNewOfferType}
            isLoading={offerTypesQuery.loading}
            isDisabled={offerTypesQuery.loading}
            placeholder="Select or type to create new"
          />
          <Button bgColor={colors.secondary} color={colors.secondaryOffset} onClick={handleManageOffers}>
            Manage Offer Types
          </Button>
        </Label>
        <Label required>
          <Text>Max Value ($)</Text>
          <NumberInput name="maxValue" value={state.maxValue} onChange={handleChange} required />
        </Label>
        <MultipleInputWrapper required onClick={focusChildInput}>
          <Text>Days Valid</Text>
          <MultipleInputLabel>
            <CheckboxInput value="1" onChange={handleDayChange} checked={state.validDays.includes("1")} />
            <RadioText>Mon</RadioText>
          </MultipleInputLabel>
          <MultipleInputLabel>
            <CheckboxInput value="2" onChange={handleDayChange} checked={state.validDays.includes("2")} />
            <RadioText>Tue</RadioText>
          </MultipleInputLabel>
          <MultipleInputLabel>
            <CheckboxInput value="3" onChange={handleDayChange} checked={state.validDays.includes("3")} />
            <RadioText>Wed</RadioText>
          </MultipleInputLabel>
          <MultipleInputLabel>
            <CheckboxInput value="4" onChange={handleDayChange} checked={state.validDays.includes("4")} />
            <RadioText>Thu</RadioText>
          </MultipleInputLabel>
          <MultipleInputLabel>
            <CheckboxInput value="5" onChange={handleDayChange} checked={state.validDays.includes("5")} />
            <RadioText>Fri</RadioText>
          </MultipleInputLabel>
          <MultipleInputLabel>
            <CheckboxInput value="6" onChange={handleDayChange} checked={state.validDays.includes("6")} />
            <RadioText>Sat</RadioText>
          </MultipleInputLabel>
          <MultipleInputLabel>
            <CheckboxInput value="0" onChange={handleDayChange} checked={state.validDays.includes("0")} />
            <RadioText>Sun</RadioText>
          </MultipleInputLabel>
          <SelectAllBtn name="validAll" value={state.validAll} onClick={handleDayChange} />
        </MultipleInputWrapper>
        <MultipleInputWrapper onClick={focusChildInput}>
          <Text>Times Valid</Text>
          <MultipleInputLabel>
            <MultipleInputText>Start</MultipleInputText>
            <StyledTimePicker
              name="timeValidStart"
              value={state.timeValidStart}
              onChange={handleValidStartTimeChange}
              disableClock
              maxDetail="minute"
            />
          </MultipleInputLabel>
          <MultipleInputLabel>
            <MultipleInputText>End</MultipleInputText>
            <StyledTimePicker
              name="timeValidEnd"
              value={state.timeValidEnd}
              onChange={handleValidEndTimeChange}
              disableClock
              maxDetail="minute"
            />
          </MultipleInputLabel>
        </MultipleInputWrapper>
        <MultipleInputWrapper required onClick={focusChildInput}>
          <Text>Redemptions</Text>
          <InlineRadioWrapper>
            <MultipleInputLabel aria-label="Max Redemptions">
              <NumberInput
                required
                name="allowedRedemptions"
                value={state.allowedRedemptions}
                onChange={handleChange}
              />
            </MultipleInputLabel>
            <StyledSelect
              name="redemptionPeriod"
              value={state.redemptionPeriod}
              onChange={handleSelect}
              options={redemptionPeriodOptions}
              isClearable
            />
          </InlineRadioWrapper>
          <MultipleInputLabel>
            <MultipleInputText style={{ marginLeft: "25px" }}>Uses Per Day</MultipleInputText>
            <NumberInput
              disabled={
                state.redemptionPeriod === redemptionPeriodOptions.find((opt) => opt.value === "perDay")
              }
              name="usesPerDay"
              value={state.usesPerDay}
              onChange={handleChange}
            />
          </MultipleInputLabel>
        </MultipleInputWrapper>
        <MultipleInputWrapper onClick={focusChildInput}>
          <Text>Resets</Text>
          <MultipleInputLabel>
            <MultipleInputText>Day</MultipleInputText>
            <StyledSelect
              name="resetDay"
              value={state.resetDay}
              onChange={handleSelect}
              options={dayDropdownOptions}
              isClearable
            />
          </MultipleInputLabel>
          <MultipleInputLabel>
            <MultipleInputText>Time</MultipleInputText>
            <StyledTimePicker
              name="resetTime"
              value={state.resetTime}
              onChange={handleResetTimeChange}
              disableClock
              maxDetail="minute"
            />
          </MultipleInputLabel>
        </MultipleInputWrapper>
        <MultipleInputWrapper required onClick={focusChildInput}>
          <Text>Expiration</Text>
          <MultipleInputLabel>
            <MultipleInputText>Start</MultipleInputText>
            <StyledDatePicker name="startDate" value={state.startDate} onChange={handleStartDateChange} />
          </MultipleInputLabel>
          <MultipleInputLabel>
            <MultipleInputText>End</MultipleInputText>
            <StyledDatePicker name="endDate" value={state.endDate} onChange={handleEndDateChange} />
          </MultipleInputLabel>
        </MultipleInputWrapper>
        <Label required>
          <Text>Image</Text>
          <ImageUpload id="image-upload" name="image" onChange={handleUpload} current={state.image} />
        </Label>
        <Label required>
          <Text>Header</Text>
          <TextInput name="header" value={state.header} onChange={handleChange} required />
        </Label>
        <Label>
          <Text>Sub Header</Text>
          <TextInput name="subheader" value={state.subheader} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Description</Text>
          <TextInput name="description" value={state.description} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Disclaimer</Text>
          <TextInput name="disclaimer" value={state.disclaimer} onChange={handleChange} />
        </Label>
        <Label required>
          <Text>Venue</Text>
          <StyledSelect
            required
            name="venues"
            options={
              venueDropdown.data &&
              venueDropdown.data.allVenues.map((venue) => ({
                value: venue.venueId,
                label: venue.name
              }))
            }
            isSearchable
            value={state.venues}
            onChange={handleSelect}
          />
        </Label>
      </Form>
      {state.isOfferTypeManagerVisible && (
        <ModalWrapper>
          <Modal>
            <ModalHeader>Offer Type Manager</ModalHeader>
            <ManagerWrapper>
              <OfferTypeList>
                {offerTypesQuery.data &&
                  offerTypesQuery.data.allOfferTypes.map((offerType) => (
                    <OfferTypeItem key={offerType.offerTypeId}>
                      <OfferTypeSpan>{offerType.text}</OfferTypeSpan>
                      <Button onClick={() => handleRemoveOfferType(offerType.offerTypeId)}>Remove</Button>
                    </OfferTypeItem>
                  ))}
              </OfferTypeList>
            </ManagerWrapper>
            <AddOfferTypeWrapper>
              <TextInput
                name={"newOfferType"}
                onChange={handleChange}
                width="100%"
                placeholder="Buy 1 Get 1 Free"
              />
              <Button onClick={addOfferType}>Add</Button>
            </AddOfferTypeWrapper>
            <ManagerBtn onClick={handleCloseManager}>Close</ManagerBtn>
          </Modal>
        </ModalWrapper>
      )}
      {state.isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Add offer and...</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={saveThenAddOffer}>Save Current Changes</Button>
              <Button onClick={addOffer}>Just Add Offer</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      {state.isDeleteVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Are you sure you want to delete this offer?</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Delete</Button>
              <Button onClick={hideDelete}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};
