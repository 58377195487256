import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../assets/brewsaver-logo-bubbles.png";

const BeerMugWrapper = styled.div`
  border-radius: 50%;
  margin: 25px;
`;

const BeerMug = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  padding: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: ${({ height, color }) => `${height * 0.04}px solid ${color || "white"}`};
  border-top: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// margin-left: -10%;
//
// &:after {
//   content: "";
//   position: absolute;
//   height: 60%;
//   width: 40%;
//   border: ${({ height, color }) => `${height * 0.04}px solid ${color || "white"}`};
//   border-left: none;
//   border-top-left-radius: 3px;
//   border-top-right-radius: 10px;
//   border-bottom-right-radius: 10px;
//   border-bottom-left-radius: 3px;
//   top: 20%;
//   right: -40%;
//   box-sizing: border-box;
// }

const Beer = styled.div`
  background-color: orange;
  height: 0%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  transition: height 1s ease-in;
  align-self: flex-end;
`;

const FoamAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  5% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
`;

const Foam = styled.div`
  border: ${({ size }) => size} solid white;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  border-radius: 50%;
  width: 0;
  height: 0;
  transform: scale(0);
  animation: ${FoamAnimation} 10s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

const Logo = styled.img`
  position: absolute;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const Text = styled.div`
  margin: 5px 0;
`;

export const BeerLoader = ({ height, color }) => {
  const [active, setActive] = React.useState(false);
  const width = height * 0.85;

  useEffect(() => {
    setActive(true);
  }, [setActive]);

  const BubbleAnimation1 = keyframes`
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(-${height * 0.75}px) translateX(-${
    width * ((Math.random() * 4) / 100)
  }px) scale(.8);
        }
    `;

  const BubbleAnimation2 = keyframes`
        0% {
            transform: translateY(0%);
        }
        93% {
            transform: translateY(-${height * 0.75}px) translateX(${
    width * ((Math.random() * 4) / 100)
  }px) scale(.9);
        }
    `;

  const BubbleAnimation3 = keyframes`
        0% {
            transform: translateY(0%);
        }
        85% {
            transform: translateY(-${height * 0.75}px) translateX(${
    width * ((Math.random() * 4) / 100)
  }px) scale(.85);
        }
    `;

  const bubbleAnimations = [BubbleAnimation1, BubbleAnimation2, BubbleAnimation3];

  const InsideBubble = styled.div`
    border: ${({ size }) => size} solid rgba(255, 255, 255, 0.25);
    position: absolute;
    bottom: ${({ bottom }) => bottom};
    left: ${({ left }) => left};
    right: ${({ right }) => right};
    border-radius: 50%;
    width: 0;
    height: 0;
    transform: scale(0);
    animation: ${({ animation }) => bubbleAnimations[animation]} 3s infinite;
    animation-delay: ${({ delay }) => delay};
  `;

  const logoSize = `${height * 0.6}px`;
  const bubbleSize = () => `${Math.random() * height * 0.05}px`;
  const bubbleLeft = () => `${Math.random() * 80 + 5}%;`;
  const foamSize = `${height * 0.125}px`;

  return (
    <BeerMugWrapper>
      <BeerMug height={height} width={width} color={color}>
        <Beer style={{ height: active && "100%" }} />
        {/* <Logo src={logo} size={logoSize} /> */}
        {active && (
          <>
            <InsideBubble size={bubbleSize()} bottom="20%" left={bubbleLeft()} animation={1} delay=".8s" />
            <InsideBubble size={bubbleSize()} bottom="11%" left={bubbleLeft()} animation={0} delay="1s" />
            <InsideBubble size={bubbleSize()} bottom="14%" left={bubbleLeft()} animation={1} delay="1.3s" />
            <InsideBubble size={bubbleSize()} bottom="6%" left={bubbleLeft()} animation={2} delay="1.5s" />
            <InsideBubble size={bubbleSize()} bottom="17%" left={bubbleLeft()} animation={0} delay="1.6s" />
            <InsideBubble size={bubbleSize()} bottom="6%" left={bubbleLeft()} animation={1} delay="1.7s" />
            <InsideBubble size={bubbleSize()} bottom="14%" left={bubbleLeft()} animation={0} delay="1.9s" />
            <InsideBubble size={bubbleSize()} bottom="17%" left={bubbleLeft()} animation={1} delay="2.1s" />
            <InsideBubble size={bubbleSize()} bottom="20%" left={bubbleLeft()} animation={2} delay="2.2s" />
            <InsideBubble size={bubbleSize()} bottom="11%" left={bubbleLeft()} animation={0} delay="2.3s" />
            <InsideBubble size={bubbleSize()} bottom="17%" left={bubbleLeft()} animation={1} delay="2.4s" />
            <InsideBubble size={bubbleSize()} bottom="14%" left={bubbleLeft()} animation={0} delay="2.6s" />
            <InsideBubble size={bubbleSize()} bottom="11%" left={bubbleLeft()} animation={1} delay="2.7s" />
            <InsideBubble size={bubbleSize()} bottom="6%" left={bubbleLeft()} animation={2} delay="2.9s" />
            <InsideBubble size={bubbleSize()} bottom="20%" left={bubbleLeft()} animation={0} delay="3s" />
            <InsideBubble size={bubbleSize()} bottom="17%" left={bubbleLeft()} animation={1} delay="3.2s" />
            <InsideBubble size={bubbleSize()} bottom="14%" left={bubbleLeft()} animation={0} delay="3.3s" />
            <InsideBubble size={bubbleSize()} bottom="11%" left={bubbleLeft()} animation={1} delay="3.4s" />
            <InsideBubble size={bubbleSize()} bottom="6%" left={bubbleLeft()} animation={2} delay="3.5s" />
            <InsideBubble size={bubbleSize()} bottom="20%" left={bubbleLeft()} animation={0} delay="3.7s" />
            <InsideBubble size={bubbleSize()} bottom="6%" left={bubbleLeft()} animation={2} delay="3.9s" />
            <InsideBubble size={bubbleSize()} bottom="20%" left={bubbleLeft()} animation={0} delay="4s" />
            <Foam size={foamSize} top="-10%" left="-12.5%" />
            <Foam size={foamSize} top="-12%" left="-4%" />
            <Foam size={foamSize} top="-6%" left="4%" />
            <Foam size={foamSize} top="-8%" left="12%" />
            <Foam size={foamSize} top="-7%" left="21%" />
            <Foam size={foamSize} top="-12%" left="29%" />
            <Foam size={foamSize} top="-7%" left="37%" />
            <Foam size={foamSize} top="-9%" left="45%" />
            <Foam size={foamSize} top="-5%" left="56%" />
            <Foam size={foamSize} top="-12%" left="64%" />
            <Foam size={foamSize} top="-7%" left="73%" />
            <Foam size={foamSize} top="-9%" left="84%" />
          </>
        )}
      </BeerMug>
    </BeerMugWrapper>
  );
};
